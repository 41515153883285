import { BaggingData } from "@/domain/entities/Bagging";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { convertQRCode } from "@/app/ui/modules/receipt/module";
import {
  dateToDateString,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import JsBarcode from "jsbarcode";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { layoutSection } from "@/app/ui/modules/receipt-v2/layout/index";
import importLogo from "@/app/ui/assets/images/logo-lion-parcel-black.png";
import { uploadFile } from "@/app/ui/modules/receipt-v2/auto-print";
import messageModal from "@/app/infrastructures/misc/common-library/MessageModal";

let doc: any = new jsPDF("p", "mm", "a6", true);

const labelPdf = async (dataBag: BaggingData[]) => {
  const importLogoColor = await import(
    `@/app/ui/assets/images/logo-lion-parcel.png`
  );
  const logo = importLogoColor.default;

  const generateLabel = (data: BaggingData, barcode: string, qrcodeValue: string) => {
    doc.addImage(logo, "PNG", 5, 3.5, 25, 5.7, "logoColor", "FAST", 0);
    doc.addImage(barcode, "PNG", 4, 22, 51, 15, `${data.bagCode}_barcode`, "FAST", 0);
    doc.addImage(qrcodeValue, "PNG", 60, 88, 43, 43, `${data.bagCode}_qrcode`, "FAST", 0);

    doc.setFillColor("#000000");
    doc.rect(1, 1, 102.9, 0.3, "F");
    doc.rect(1, 1, 0.3, 145.85, "F");
    doc.rect(103.62, 1, 0.3, 145.85, "F");
    doc.rect(1, 146.56, 102.9, 0.3, "F");
    doc.rect(62, 1, 0.3, 11, "F");
    doc.rect(1, 12, 102.9, 0.3, "F");
    doc.rect(1, 51.4, 102.9, 0.3, "F");
    doc.rect(1, 87.8, 102.9, 0.3, "F");
    doc.rect(1, 133.8, 102.9, 0.3, "F");

    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    doc.setTextColor("#333333");
    const dateString = dateToDateString(data.bagCreatedAt);
    const substractX =
      dateString.split(" ")[0].length + dateString.split(" ")[1].length;
    doc.text(dateString, -0.8214 * substractX + 77.857, 7.5);

    doc.text(ellipsisString(data.bagPartnerName, 45), 5, 19);

    doc.setFontSize(20);
    doc.setFont("helvetica", "bold");
    doc.text(data.bagCode, 5, 45);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text("Kota Asal", 5, 60);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(34);
    doc.text(data.bagOriginCityCode, 5, 75);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(data.bagOriginCityName, 5, 81);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text("Kota Tujuan", 65, 60);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(34);
    doc.text(data.bagDestinationCityCode, 65, 75);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(data.bagDestinationCityName, 65, 81);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text(
      `${
        data.bagCustomGrossWeight > data.bagCustomVolumeWeight
          ? data.bagCustomGrossWeight
          : data.bagCustomVolumeWeight
      } Kg`,
      5,
      101
    );

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`${data.bagCustomGrossWeight} Kg`, 5, 114);
    doc.text(`${data.bagCustomVolumeWeight} Kg`, 5, 126);
    doc.text(`${data.bagTotalStt}`, 45, 114);
    doc.text(`${data.bagTotalPiece}`, 45, 126);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text("Total Bag Chargeable Weight", 5, 95);
    doc.text("Total Bag Gross Weight", 5, 110);
    doc.text("Total STT", 45, 110);
    doc.text("Total Bag Volume Weight", 5, 122);
    doc.text("Total Koli", 45, 122);
    doc.setFontSize(6);
    doc.text(
      "Terjadi Kendala pada proses pengiriman? Hubungi Customer Service 021-80820072\natau hubungi Origin yang bersangkutan.",
      5,
      140
    );
  };

  for (let i = 0; i < dataBag.length; i++) {
    if (i > 0) {
      doc.addPage("a6", "p");
    }
    const barcode = document.createElement("img");
    JsBarcode(barcode, dataBag[i].bagCode, {
      textAlign: "left",
      displayValue: false,
      fontSize: 16
    });
    const combineQRText = `${dataBag[i].bagCode}#${
      dataBag[i].bagOriginCityCode
    }#${dataBag[i].bagDestinationCityCode}#${dataBag[i].bagTotalChargeableWeight}#${
      dataBag[i].bagCustomGrossWeight
    }#${dataBag[i].bagCustomVolumeWeight}#${dataBag[i].bagCreatedAt.slice(0, 10)}`;

    const barcodeSrc = barcode.src;

    const qrcodeValue = await convertQRCode(combineQRText);
    generateLabel(dataBag[i], barcodeSrc, qrcodeValue);
  }
};

const openTab = (docs: any) => {
  docs.setProperties({
    title: `resi-thermal.pdf`
  });

  docs.autoPrint();

  window.open(docs.output("bloburl"), "_blank");

  doc = new jsPDF("p", "mm", "a6", true); //reset after finish
};

const generateThermalPDF = async (
  accountType: string,
  data: PrintDataEntitas[],
  dataBag: BaggingData[]
) => {
  const logo = importLogo;
  let index = 0;
  const isDataSttExist = data.length > 0;
  if (isDataSttExist) {
    for (const item of data) {
      if (index > 0) {
        doc.addPage("a6", "p");
      }
      const language = item.sttProductType === "INTERPACK" ? "en" : "id";
      const bookingType = () => {
        if (item.sttShipmentId) {
          return "shipment";
        } else if (!item.sttShipmentId && !item.sttClientId) {
          return "manual";
        } else {
          return "client";
        }
      };
      await layoutSection(
        {
          doc,
          bookingType: bookingType(),
          accountType,
          data: item,
          pieceData: item,
          barcode: item.barcode,
          vendorRefCode: item.vendorRefCode,
          barcodeValue: item.sttNo,
          vendorRefCodeValue: item.sttVendorRefferenceNo,
          logo,
          shipmentId: item.sttShipmentId,
          startX: 0,
          startY: 0
        },
        language
      );

      index++;
    }
  }

  const print = await uploadFile(
    doc.output("blob"),
    `${data[0]?.sttNo}-bulk`,
    false
  );

  if (dataBag.length) {
    isDataSttExist && doc.addPage("a6", "p");
    await labelPdf(dataBag);
  }
  if (!print) {
    openTab(doc);
    messageModal(
      "Label Gagal Diprint",
      "circle-error",
      "Pastikan semua perangkat Anda telah terhubung. <br/> Cek & coba lagi"
    );
  } else {
    messageModal(
      "Label Berhasil Diprint",
      "badge-success",
      "Anda berhasil mencetak data yang diinginkan"
    );
  }
};

export default generateThermalPDF;
